import React from "react";
import PageTitle from "../PageTitle";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";
import PageContainer from "../PageContainer";
import { Container, Card, CardHeader, CardContents } from "./styles";
import TouringBg from "./Images";
import {
    APLogoImg,
    APLogoMobileImg,
    LogoImg,
    Row,
    StyledAnchor,
} from "../partners/styles";
import Image from "../partners/Images";

export const TouringDetails = () => {
    const parnter = Image();
    return (
        <Card>
            <CardHeader>
                Launch of the KOFFIA Touring Program in Collaboration with Local
                Councils
            </CardHeader>
            <Row>
                <StyledAnchor
                    href="https://alicesprings.nt.gov.au/"
                    target="_blank"
                >
                    <LogoImg fixed={parnter.alicespringsImage} />
                </StyledAnchor>
                <StyledAnchor
                    href="https://www.benalla.vic.gov.au/Home"
                    target="_blank"
                >
                    <LogoImg fixed={parnter.benellaImage} />
                </StyledAnchor>
                <StyledAnchor
                    href="https://www.burnside.sa.gov.au/Home"
                    target="_blank"
                >
                    <APLogoImg fixed={parnter.burnsideImage} />
                    <APLogoMobileImg fixed={parnter.burnsideMobileImage} />
                </StyledAnchor>
                {/* <StyledAnchor
                href="https://canberra.com.au/"
                target="_blank"
            >
                <APLogoImg fixed={parnter.canberraImage} />
                <APLogoMobileImg
                    fixed={parnter.canberraMobileImage}
                />
            </StyledAnchor> */}
            </Row>

            <CardContents>
                Korean films are becoming a global phenomenon through cinemas
                and OTT platforms, yet many people in Australia have not had the
                opportunity to experience them. To introduce the finest Korean
                films to local residents across Australia, the Korean Film
                Festival in Australia (KOFFIA) is launching its first-ever
                touring program this year. We are excited to bring KOFFIA 2024
                to Canberra, Alice Springs, Benalla, and Burnside.
            </CardContents>

            <CardContents>
                This year, we are bringing four films from the 2024 KOFFIA full
                line-up and offering free screenings for local residents in
                these areas. The touring program is a free admission event,
                ensuring everyone can enjoy the magic of Korean cinema. We
                appreciate the cooperation from Alice Springs Town, Benalla
                Rural City, and the City of Burnside in making this program
                possible.
            </CardContents>

            <CardContents>
                Join us as we bring the magic of Korean cinema to your community
                and celebrate the richness of Korean storytelling.
            </CardContents>
        </Card>
    );
};

const TouringInfo = () => {
    return (
        <PageContainer>
            <Breadcrumb>
                <BreadcrumbItem
                    Link={"/touring/intro"}
                    Text="Touring Program"
                />
            </Breadcrumb>
            <Container>
                <TouringBg />
                <PageTitle>Touring Program</PageTitle>
                <TouringDetails />
            </Container>
        </PageContainer>
    );
};

export default TouringInfo;
