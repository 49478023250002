import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import TouringInfo from "../../components/touring-intro";

const Canberra = () => (
    <Layout>
        <SEO title="KOFFIA Touring Program" />
        <TouringInfo />
    </Layout>
);

export default Canberra;
