import { useStaticQuery, graphql } from "gatsby";

const Image = () => {
    const data = useStaticQuery(graphql`
        fragment kccauLogoImage on File {
            childImageSharp {
                fluid(maxHeight: 112) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    ...GatsbyImageSharpFluidLimitPresentationSize
                }
            }
        }
        fragment fluidLogoImage on File {
            childImageSharp {
                fluid(maxHeight: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    ...GatsbyImageSharpFluidLimitPresentationSize
                }
            }
        }
        fragment kocisImage on File {
            childImageSharp {
                fixed(height: 100) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
            }
        }
        fragment cgroksImage on File {
            childImageSharp {
                fixed(height: 106) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
            }
        }
        fragment asianaImage on File {
            childImageSharp {
                fixed(height: 90) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
            }
        }
        fragment ktoImage on File {
            childImageSharp {
                fixed(height: 80) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
            }
        }
        fragment jonggaImage on File {
            childImageSharp {
                fixed(height: 120) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
            }
        }
        fragment mcaImage on File {
            childImageSharp {
                fixed(height: 120) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
            }
        }
        fragment sffImage on File {
            childImageSharp {
                fixed(height: 120) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
            }
        }
        fragment roundlabImage on File {
            childImageSharp {
                fixed(height: 120) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
            }
        }
        fragment redginsengImage on File {
            childImageSharp {
                fixed(height: 120) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
            }
        }
        fragment eventImage on File {
            childImageSharp {
                fixed(height: 120) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
            }
        }
        fragment palaceImage on File {
            childImageSharp {
                fixed(height: 90) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
            }
        }
        fragment unswImage on File {
            childImageSharp {
                fixed(height: 90) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
            }
        }
        fragment SmallLogoImage on File {
            childImageSharp {
                fixed(height: 60) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
            }
        }

        fragment OtherLogoImage on File {
            childImageSharp {
                fixed(height: 70) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
            }
        }
        fragment OtherDoubleHeightLogoImage on File {
            childImageSharp {
                fixed(height: 100) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
            }
        }
        fragment CarouselImage on File {
            childImageSharp {
                fixed(height: 50, grayscale: true) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
            }
        }
        fragment CarouselSmallImage on File {
            childImageSharp {
                fixed(height: 38, grayscale: true) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
            }
        }
        fragment CarouselLargeImage on File {
            childImageSharp {
                fixed(height: 100, grayscale: true) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
            }
        }
        fragment fluidSponsorImage on File {
            childImageSharp {
                fluid(maxWidth: 240) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    ...GatsbyImageSharpFluidLimitPresentationSize
                }
            }
        }
        fragment fluidSponsorLongImage on File {
            childImageSharp {
                fluid(maxWidth: 220) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    ...GatsbyImageSharpFluidLimitPresentationSize
                }
            }
        }
        query {
            kccauLogoImage: file(
                relativePath: { eq: "partners/kccauLogo.png" }
            ) {
                ...kccauLogoImage
            }
            mcstImage: file(relativePath: { eq: "partners/mcst.png" }) {
                ...OtherLogoImage
            }
            mfaImage: file(relativePath: { eq: "partners/mfa.png" }) {
                ...OtherLogoImage
            }
            erokcoaImage: file(relativePath: { eq: "partners/erokcoa.png" }) {
                ...OtherLogoImage
            }
            erokcoaMobileImage: file(
                relativePath: { eq: "partners/erokcoa.png" }
            ) {
                ...SmallLogoImage
            }
            cgroksImage: file(relativePath: { eq: "partners/cgroks.png" }) {
                ...cgroksImage
            }
            cgrokmImage: file(relativePath: { eq: "partners/MEL.png" }) {
                ...OtherDoubleHeightLogoImage
            }
            alicespringsImage: file(
                relativePath: { eq: "partners/ASTC-CMYK.png" }
            ) {
                ...cgroksImage
            }
            benellaImage: file(relativePath: { eq: "partners/benella.png" }) {
                ...cgroksImage
            }
            burnsideImage: file(
                relativePath: { eq: "partners/burnside-1.png" }
            ) {
                ...cgroksImage
            }
            burnsideMobileImage: file(
                relativePath: { eq: "partners/burnside-1.png" }
            ) {
                ...SmallLogoImage
            }
            canberraImage: file(relativePath: { eq: "partners/canberra.png" }) {
                ...cgroksImage
            }
            canberraMobileImage: file(
                relativePath: { eq: "partners/canberra.png" }
            ) {
                ...SmallLogoImage
            }
            ktoImage: file(relativePath: { eq: "partners/kto_300.png" }) {
                ...ktoImage
            }
            filminkImage: file(relativePath: { eq: "partners/filmink.png" }) {
                ...OtherLogoImage
            }
            filmedInEtherImage: file(
                relativePath: { eq: "partners/filmedinether.png" }
            ) {
                ...OtherLogoImage
            }
            oFoodImage: file(
                relativePath: { eq: "partners/Ofood_BI_300.png" }
            ) {
                ...OtherLogoImage
            }
            nongshimImage: file(
                relativePath: { eq: "partners/nongshim-logo.png" }
            ) {
                ...OtherDoubleHeightLogoImage
            }
            nongshimMobileImage: file(
                relativePath: { eq: "partners/nongshim-logo.png" }
            ) {
                ...SmallLogoImage
            }
            jonggaImage: file(
                relativePath: { eq: "partners/Jongga_BI_300.png" }
            ) {
                ...jonggaImage
            }
            asianaImage: file(relativePath: { eq: "partners/Asiana_300.png" }) {
                ...asianaImage
            }
            asianaMobileImage: file(
                relativePath: { eq: "partners/Asiana_300.png" }
            ) {
                ...SmallLogoImage
            }
            ktoFluidImage: file(relativePath: { eq: "partners/kto_300.png" }) {
                ...fluidSponsorImage
            }
            nongshimFluidImage: file(
                relativePath: { eq: "partners/nongshim-logo.png" }
            ) {
                ...fluidSponsorLongImage
            }
            asianaFluidImage: file(
                relativePath: { eq: "partners/Asiana_300.png" }
            ) {
                ...fluidSponsorImage
            }
            oFoodFluidImage: file(
                relativePath: { eq: "partners/Ofood_BI_300.png" }
            ) {
                ...fluidSponsorImage
            }
            jonggaFluidImage: file(
                relativePath: { eq: "partners/jongga-2.png" }
            ) {
                ...fluidSponsorImage
            }
            palaceImage: file(relativePath: { eq: "partners/palace.png" }) {
                ...palaceImage
            }
            palaceCarouselImage: file(
                relativePath: { eq: "partners/palace.png" }
            ) {
                ...CarouselImage
            }
            regalImage: file(relativePath: { eq: "partners/regal.png" }) {
                ...palaceImage
            }
            regalMobileImage: file(relativePath: { eq: "partners/regal.png" }) {
                ...SmallLogoImage
            }
            regalCarouselImage: file(
                relativePath: { eq: "partners/regal.png" }
            ) {
                ...CarouselImage
            }
            unswImage: file(relativePath: { eq: "partners/unsw.png" }) {
                ...unswImage
            }
            unswCarouselImage: file(relativePath: { eq: "partners/unsw.png" }) {
                ...CarouselImage
            }
            originalSpinImage: file(
                relativePath: { eq: "partners/original_spin_300.png" }
            ) {
                ...OtherLogoImage
            }
            originalSpinMobileImage: file(
                relativePath: { eq: "partners/originalspin2.png" }
            ) {
                ...OtherDoubleHeightLogoImage
            }
            acmiImage: file(
                relativePath: { eq: "partners/ACMI_Logo_RGB_Black.png" }
            ) {
                ...OtherLogoImage
            }
            acmiMobileImage: file(
                relativePath: { eq: "partners/ACMI_Logo_RGB_Black.png" }
            ) {
                ...SmallLogoImage
            }
            gonImage: file(relativePath: { eq: "partners/gon-1.png" }) {
                ...OtherLogoImage
            }
            kccauCarouselImage: file(
                relativePath: { eq: "partners/kccauLogo.png" }
            ) {
                ...CarouselImage
            }
            mcstCarouselImage: file(relativePath: { eq: "partners/mcst.png" }) {
                ...CarouselImage
            }
            mfaCarouselImage: file(relativePath: { eq: "partners/mfa.png" }) {
                ...CarouselImage
            }
            erokcoaCarouselImage: file(
                relativePath: { eq: "partners/erokcoa.png" }
            ) {
                ...CarouselImage
            }
            cgroksCarouselImage: file(
                relativePath: { eq: "partners/cgroks.png" }
            ) {
                ...CarouselImage
            }
            cgrokmCarouselImage: file(
                relativePath: { eq: "partners/cgrokm.png" }
            ) {
                ...CarouselImage
            }
            alicespringsCarouselImage: file(
                relativePath: { eq: "partners/ASTC-CMYK.png" }
            ) {
                ...CarouselImage
            }
            benellaCarouselImage: file(
                relativePath: { eq: "partners/benella.png" }
            ) {
                ...CarouselImage
            }
            burnsideCarouselImage: file(
                relativePath: { eq: "partners/burnside-1.png" }
            ) {
                ...CarouselImage
            }

            ktoCarouselImage: file(
                relativePath: { eq: "partners/kto_300.png" }
            ) {
                ...CarouselImage
            }
            filminkCarouseImage: file(
                relativePath: { eq: "partners/filmink.png" }
            ) {
                ...CarouselImage
            }
            filmedInEtherCarouselImage: file(
                relativePath: { eq: "partners/filmedinether.png" }
            ) {
                ...CarouselImage
            }
            oFoodCarouselImage: file(
                relativePath: { eq: "partners/Ofood_BI_300.png" }
            ) {
                ...CarouselImage
            }
            nongshimCarouselImage: file(
                relativePath: { eq: "partners/nongshim-logo.png" }
            ) {
                ...CarouselImage
            }
            jonggaCarouselImage: file(
                relativePath: { eq: "partners/Jongga_BI_300.png" }
            ) {
                ...CarouselImage
            }
            originalSpinCarouselImage: file(
                relativePath: { eq: "partners/original_spin_300.png" }
            ) {
                ...CarouselImage
            }
            acmiCarouselImage: file(
                relativePath: { eq: "partners/ACMI_Logo_RGB_Black.png" }
            ) {
                ...CarouselImage
            }
            gonCarouselImage: file(relativePath: { eq: "partners/gon-1.png" }) {
                ...CarouselImage
            }
            asianaCarouselImage: file(
                relativePath: { eq: "partners/Asiana_300.png" }
            ) {
                ...CarouselImage
            }
            goodneighboursImage: file(
                relativePath: {
                    eq: "partners/Good Neighbours_Type A_Australia_color.jpg"
                }
            ) {
                ...OtherLogoImage
            }
            goodneighboursMobileImage: file(
                relativePath: {
                    eq: "partners/Good Neighbours_Type B_Australia_color.jpg"
                }
            ) {
                ...OtherDoubleHeightLogoImage
            }
            goodneighboursCarouselImage: file(
                relativePath: {
                    eq: "partners/Good Neighbours_Type B_Australia_color.jpg"
                }
            ) {
                ...CarouselImage
            }
            elizabethImage: file(
                relativePath: {
                    eq: "partners/Elizabeth_Picture_Theatre_black.png"
                }
            ) {
                ...OtherLogoImage
            }
            elizabethMobileImage: file(
                relativePath: {
                    eq: "partners/Elizabeth_Picture_Theatre_black.png"
                }
            ) {
                ...SmallLogoImage
            }
            elizabethCarouselImage: file(
                relativePath: {
                    eq: "partners/Elizabeth_Picture_Theatre_black.png"
                }
            ) {
                ...CarouselImage
            }
            eventImage: file(
                relativePath: { eq: "partners/Event-Cinemas-Featured.png" }
            ) {
                ...eventImage
            }
            eventCarouselImage: file(
                relativePath: { eq: "partners/Event-Cinemas-Featured.png" }
            ) {
                ...CarouselImage
            }
            mcaImage: file(
                relativePath: { eq: "partners/MCA_Australia_MONO.png" }
            ) {
                ...mcaImage
            }
            mcaCarouselImage: file(
                relativePath: { eq: "partners/MCA_Australia_MONO.png" }
            ) {
                ...CarouselImage
            }
            sffImage: file(
                relativePath: { eq: "partners/SYDFILMFEST_Black.png" }
            ) {
                ...sffImage
            }
            sffCarouselImage: file(
                relativePath: { eq: "partners/SYDFILMFEST_Black.png" }
            ) {
                ...CarouselImage
            }
            agnswImage: file(
                relativePath: { eq: "partners/AGNSW_SubBrand_300.png" }
            ) {
                ...sffImage
            }
            agnswCarouselImage: file(
                relativePath: { eq: "partners/AGNSW_SubBrand_300.png" }
            ) {
                ...CarouselImage
            }
            roundlabImage: file(
                relativePath: { eq: "partners/round_lab_300.png" }
            ) {
                ...roundlabImage
            }
            roundlabCarouselImage: file(
                relativePath: { eq: "partners/round_lab_300.png" }
            ) {
                ...CarouselImage
            }
            redginsengImage: file(
                relativePath: { eq: "partners/KGC_LOGO.png" }
            ) {
                ...redginsengImage
            }
            redginsengCarouselImage: file(
                relativePath: { eq: "partners/KGC_LOGO.png" }
            ) {
                ...CarouselLargeImage
            }
            kocisImage: file(relativePath: { eq: "partners/kocis-4.png" }) {
                ...kocisImage
            }
            kocisCarouselImage: file(
                relativePath: { eq: "partners/kocis-4.png" }
            ) {
                ...CarouselImage
            }
        }
    `);

    return {
        kccauLogoImage: data.kccauLogoImage.childImageSharp.fluid,
        mcstImage: data.mcstImage.childImageSharp.fixed,
        mfaImage: data.mfaImage.childImageSharp.fixed,
        erokcoaImage: data.erokcoaImage.childImageSharp.fixed,
        erokcoaMobileImage: data.erokcoaMobileImage.childImageSharp.fixed,
        cgroksImage: data.cgroksImage.childImageSharp.fixed,
        cgrokmImage: data.cgrokmImage.childImageSharp.fixed,
        alicespringsImage: data.alicespringsImage.childImageSharp.fixed,
        benellaImage: data.benellaImage.childImageSharp.fixed,
        burnsideImage: data.burnsideImage.childImageSharp.fixed,
        burnsideMobileImage: data.burnsideMobileImage.childImageSharp.fixed,
        canberraImage: data.canberraImage.childImageSharp.fixed,
        canberraMobileImage: data.canberraMobileImage.childImageSharp.fixed,
        ktoImage: data.ktoImage.childImageSharp.fixed,
        filminkImage: data.filminkImage.childImageSharp.fixed,
        filmedInEtherImage: data.filmedInEtherImage.childImageSharp.fixed,
        oFoodImage: data.oFoodImage.childImageSharp.fixed,
        nongshimImage: data.nongshimImage.childImageSharp.fixed,
        nongshimMobileImage: data.nongshimMobileImage.childImageSharp.fixed,
        jonggaImage: data.jonggaImage.childImageSharp.fixed,
        asianaImage: data.asianaImage.childImageSharp.fixed,
        asianaMobileImage: data.asianaMobileImage.childImageSharp.fixed,

        ktoFluidImage: data.ktoFluidImage.childImageSharp.fluid,
        nongshimFluidImage: data.nongshimFluidImage.childImageSharp.fluid,
        asianaFluidImage: data.asianaFluidImage.childImageSharp.fluid,
        oFoodFluidImage: data.oFoodFluidImage.childImageSharp.fluid,
        jonggaFluidImage: data.jonggaFluidImage.childImageSharp.fluid,

        originalSpinImage: data.originalSpinImage.childImageSharp.fixed,
        originalSpinMobileImage:
            data.originalSpinMobileImage.childImageSharp.fixed,
        acmiImage: data.acmiImage.childImageSharp.fixed,
        acmiMobileImage: data.acmiMobileImage.childImageSharp.fixed,
        gonImage: data.gonImage.childImageSharp.fixed,
        kccauCarouselImage: data.kccauCarouselImage.childImageSharp.fixed,
        mcstCarouselImage: data.mcstCarouselImage.childImageSharp.fixed,
        mfaCarouselImage: data.mfaCarouselImage.childImageSharp.fixed,
        erokcoaCarouselImage: data.erokcoaCarouselImage.childImageSharp.fixed,
        kocisCarouselImage: data.kocisCarouselImage.childImageSharp.fixed,
        cgroksCarouselImage: data.cgroksCarouselImage.childImageSharp.fixed,
        cgrokmCarouselImage: data.cgrokmCarouselImage.childImageSharp.fixed,
        alicespringsCarouselImage:
            data.alicespringsCarouselImage.childImageSharp.fixed,
        benellaCarouselImage: data.benellaCarouselImage.childImageSharp.fixed,
        burnsideCarouselImage: data.burnsideCarouselImage.childImageSharp.fixed,
        ktoCarouselImage: data.ktoCarouselImage.childImageSharp.fixed,
        filminkCarouseImage: data.filminkCarouseImage.childImageSharp.fixed,
        filmedInEtherCarouselImage:
            data.filmedInEtherCarouselImage.childImageSharp.fixed,
        oFoodCarouselImage: data.oFoodCarouselImage.childImageSharp.fixed,
        nongshimCarouselImage: data.nongshimCarouselImage.childImageSharp.fixed,
        jonggaCarouselImage: data.jonggaCarouselImage.childImageSharp.fixed,
        originalSpinCarouselImage:
            data.originalSpinCarouselImage.childImageSharp.fixed,
        acmiCarouselImage: data.acmiCarouselImage.childImageSharp.fixed,
        gonCarouselImage: data.gonCarouselImage.childImageSharp.fixed,
        asianaCarouselImage: data.asianaCarouselImage.childImageSharp.fixed,
        goodneighboursImage: data.goodneighboursImage.childImageSharp.fixed,
        goodneighboursMobileImage:
            data.goodneighboursMobileImage.childImageSharp.fixed,
        goodneighboursCarouselImage:
            data.goodneighboursCarouselImage.childImageSharp.fixed,
        eventImage: data.eventImage.childImageSharp.fixed,
        eventCarouselImage: data.eventCarouselImage.childImageSharp.fixed,
        elizabethImage: data.elizabethImage.childImageSharp.fixed,
        elizabethMobileImage: data.elizabethMobileImage.childImageSharp.fixed,
        elizabethCarouselImage:
            data.elizabethCarouselImage.childImageSharp.fixed,
        palaceImage: data.palaceImage.childImageSharp.fixed,
        palaceCarouselImage: data.palaceCarouselImage.childImageSharp.fixed,
        regalImage: data.regalImage.childImageSharp.fixed,
        regalMobileImage: data.regalMobileImage.childImageSharp.fixed,
        regalCarouselImage: data.regalCarouselImage.childImageSharp.fixed,
        unswImage: data.unswImage.childImageSharp.fixed,
        unswCarouselImage: data.unswCarouselImage.childImageSharp.fixed,
        mcaImage: data.mcaImage.childImageSharp.fixed,
        mcaCarouselImage: data.mcaCarouselImage.childImageSharp.fixed,
        sffImage: data.sffImage.childImageSharp.fixed,
        sffCarouselImage: data.sffCarouselImage.childImageSharp.fixed,
        agnswImage: data.agnswImage.childImageSharp.fixed,
        agnswCarouselImage: data.agnswCarouselImage.childImageSharp.fixed,
        roundlabImage: data.roundlabImage.childImageSharp.fixed,
        roundlabCarouselImage: data.roundlabCarouselImage.childImageSharp.fixed,
        redginsengImage: data.redginsengImage.childImageSharp.fixed,
        redginsengCarouselImage:
            data.redginsengCarouselImage.childImageSharp.fixed,
        kocisImage: data.kocisImage.childImageSharp.fixed,
    };
};
export default Image;
