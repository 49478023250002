import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";
import Img from "gatsby-image";

export const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 4rem;
`;
export const SubRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
`;
export const Title = styled.h1`
    font-size: 2.125rem;
    text-align: left;
    font-weight: 400;
    margin: 0 0.5rem 3rem 0;
    @media (min-width: ${breakpoints.sm}) {
        font-size: 2.5rem;
        margin: 4rem 0 3rem;
    }
`;
export const SubTitle = styled.h2`
    font-size: 1.75rem;
    color: ${colors.dark};
    text-align: left;
    font-weight: 400;
    margin: 0 0 1.75rem;
    padding-bottom: 0.75rem;
    border-bottom: 1.5px solid ${colors.darkGray};
    text-transform: uppercase;
`;
export const LogoImg = styled(Img)`
    display: block;
    height: 5.5rem;
    margin: 1.25rem 1rem 1.25rem 0;
    @media (min-width: ${breakpoints.sm}) {
        height: 7rem;
        margin: 1.25rem 1rem;
    }
`;
export const GPLogoImg = styled(Img)`
    display: block;
    height: 3.125rem !important;
    width: 16.62rem !important;
    margin: 1.25rem 0.25rem;
    @media (min-width: ${breakpoints.sm}) {
        height: 4.325rem !important;
        width: 22rem !important;
        margin: 1.25rem 2.5rem;
    }
`;
export const MfaLogoImg = styled(Img)`
    display: block;
    height: 3.125rem !important;
    width: 12.4rem !important;
    margin: 1.25rem 0.625rem;
    @media (min-width: ${breakpoints.sm}) {
        height: 4.325rem !important;
        width: 17rem !important;
        margin: 1.25rem 2.5rem;
    }
`;
export const FPLogoImg = styled(Img)`
    display: block;
    height: 3.125rem !important;
    width: 11.66rem !important;
    margin: 1.25rem 1rem;
    @media (min-width: ${breakpoints.sm}) {
        height: 4.325rem !important;
        width: 16.15rem !important;
        margin: 1.25rem 2.5rem;
    }
`;
export const FluidLogoImg = styled(Img)`
    display: block;
    height: 5.5rem;
    width: 100%;
    margin: 1.25rem 0.625rem;
    @media (min-width: ${breakpoints.sm}) {
        margin: 1.25rem 2rem;
        height: ${props => props.height};
    }
`;
export const FPSubLogoImg = styled(Img)`
    display: block;
    height: 5.5rem;
    width: 100%;
    margin: 1.25rem 0.625rem;
    @media (min-width: ${breakpoints.sm}) {
        margin: 1.25rem 2rem;
    }
`;

export const GPSubLogoImg = styled(Img)`
    display: none !important;
    height: 5.5rem;
    width: 100%;
    margin: 1.25rem 0.625rem;
    @media (min-width: ${breakpoints.sm}) {
        margin: 1.25rem 2rem;
        display: block !important;
    }
`;
export const GPSubLogoMobileImg = styled(Img)`
    display: block !important;
    height: 5.5rem;
    width: 100%;
    margin: 1.25rem 0.625rem;
    @media (min-width: ${breakpoints.sm}) {
        margin: 1.25rem 2rem;
        display: none !important;
    }
`;
export const SPSubLogoImg = styled(Img)`
    display: block;
    height: 4.5rem;
    margin: 1.25rem 0 1.25rem 1rem;
    @media (min-width: ${breakpoints.sm}) {
        margin: 1rem 2.5rem;
    }
`;
export const SPLogoImg = styled(Img)`
    margin: 1.25rem 0 1.25rem 1rem;
    @media (min-width: ${breakpoints.sm}) {
        margin: 1rem 2.5rem;
    }
`;

export const JpLogoImg = styled(Img)`
    margin: 1.25rem 7rem 1.25rem 1rem;
    @media (min-width: ${breakpoints.sm}) {
        margin: 1rem 3.5rem;
    }
`;

export const APLogoImg = styled(Img)`
    display: none !important;
    margin: 1.25rem 0 1.25rem 1rem;
    @media (min-width: ${breakpoints.sm}) {
        margin: 1rem 2.5rem;
        display: flex !important;
        align-items: center;
    }
`;
export const APLogoMobileImg = styled(Img)`
    display: block !important;
    margin: 1.25rem 0 1.25rem 1rem;
    @media (min-width: ${breakpoints.sm}) {
        margin: 1rem 2.5rem;
        display: none !important;
    }
`;
export const OSLogoImg = styled(Img)`
    display: none !important;
    height: 4.5rem;
    margin: 1.25rem 0 1.25rem 1rem;
    @media (min-width: ${breakpoints.sm}) {
        display: block !important;
        margin: 1rem 2.5rem;
    }
`;
export const OSMobileLogoImg = styled(Img)`
    display: block !important;
    height: 4.5rem;
    margin: 1.25rem 0 1.25rem 1rem;
    @media (min-width: ${breakpoints.sm}) {
        margin: 1rem 2.5rem;
        display: none !important;
    }
`;
export const VPLogoImg = styled(Img)`
    display: block;
    height: 3rem !important;
    width: 13.7rem !important;
    margin: 1.25rem auto 1.25rem 0.5rem;
    @media (min-width: 325px) {
        height: 4rem;
        margin: 1.25rem auto 1rem 1.5rem;
    }
    @media (min-width: ${breakpoints.sm}) {
        height: 4rem;
        margin: 1.25rem auto 1rem 2.5rem;
    }
`;
export const PartnerSponsorImg = styled(Img)`
    display: block;
    margin: 1.25rem 0 1.25rem 1rem;
    @media (min-width: ${breakpoints.sm}) {
        margin: 0 1rem;
    }
    @media (min-width: ${breakpoints.md}) {
        margin: 0 2rem;
    }
`;
export const StyledAnchor = styled.a`
    font-size: 1rem;
    color: ${colors.dark};
    text-decoration: none;
    width: 100%;
    margin: auto 0;
    display: block
    &:hover {
        color: ${colors.primary};
    }
    @media (min-width: ${breakpoints.md}) {
        flex: 1;
    }
   
`;
export const StyledPartnerAnchor = styled(StyledAnchor)`
    @media (min-width: ${breakpoints.lg}) {
        width: 20%;
    }
`;
export const CarouselImg = styled(Img)`
    display: block;
    margin: 1.25rem;
    @media (min-width: ${breakpoints.sm}) {
        margin: 2rem 3rem;
    }
`;
export const CarouselSmallImg = styled(Img)`
    display: block;
    margin: 1.625rem 1.25rem;
    @media (min-width: ${breakpoints.sm}) {
        margin: 2.375rem 3rem;
    }
`;
export const CarouselLargeImg = styled(Img)`
    display: block;
    margin: 0.5rem 1.25rem;
    @media (min-width: ${breakpoints.sm}) {
        margin: 0.5rem 3rem;
    }
`;
export const CarouselWrapper = styled.section`
    width: 100%;
    padding: 2rem 0 4rem;
    background-color: #fff;
`;

export const CarouselContainer = styled.div`
    max-width: ${breakpoints.xl};
    margin: 0 auto;
    padding: 3rem 2rem 5rem 2rem;

    @media (min-width: ${breakpoints.sm}) {
        padding: 1rem 2rem 2rem 2rem;
    }
    .slick-list {
        margin: 0 1rem;
    }
    .slick-prev,
    .slick-next {
        height: auto;
        margin: -0.5rem 0.5rem;
    }
    .slick-prev:before {
        content: "〈";
        color: #333333;
        font-size: 2rem;
        font-weight: 900;
    }
    .slick-next:before {
        content: "〉";
        color: #333333;
        font-size: 2rem;
        font-weight: 900;
    }
    .slick-slider {
        height: 8.3125rem;
    }
`;
