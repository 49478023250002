import styled from "styled-components";

import { breakpoints } from "../../styles/variables";
export const Container = styled.div`
    padding-top: 0;
    @media (min-width: ${breakpoints.sm}) {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
`;

export const Card = styled.div`
    border: 1px solid #efeaea;
    margin-bottom: 2rem;
    @media (min-width: ${breakpoints.sm}) {
        margin-bottom: 0;
    }
`;

export const CardHeader = styled.p`
    font-size: 1.3rem;
    padding: 1rem 1rem 1rem 1rem;
    border-bottom: 1px solid #efeaea;
    background-color: #f7f5f530;

    @media (min-width: ${breakpoints.sm}) {
        padding: 1rem 1rem 1rem 2rem;
        font-size: 1.5rem;
    }
`;
export const CardContents = styled.p`
    padding-left: 1rem;

    &:last-child {
        padding-bottom: 2rem;
    }
    @media (min-width: ${breakpoints.sm}) {
        padding-left: 2rem;
    }
`;
