import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const TouringBg = () => {
    const data = useStaticQuery(graphql`
        query {
            artWork: file(relativePath: { eq: "KOFFIA_2024_Website_2.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1200) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    return <Img fluid={data.artWork.childImageSharp.fluid} />;
};

export default TouringBg;
